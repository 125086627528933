import React from "react"
import { graphql } from "gatsby"
import Post from "../components/post"
import Layout from "../components/layout"


const Thoughts = ({data: {allMarkdownRemark:{ edges },},}) => {
    const Posts = edges
            .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
            .map(edge => <Post key={edge.node.id} post={edge.node} />)
    
            return (
        <Layout>
          {/* <SEO title="Articles"  /> */}
          
          <div className='container__projects' id='sectiontitle'>
                <h2 style={{textAlign:'center', padding:'2.5rem 0'}}> Thoughts, Insights & Reflections. </h2>
  
              <div className='container__maintext'>
                <h6> I love devoting time to understand problem spaces from various perspectives and approaches. With the multiplicity of hats I have worn to date - I evolved my toolkit of frameworks, processes, and lean methodologies to evolve digital solutions with a larger view of how things could unfold.  </h6>       
                <h6 style={{paddingTop:'1.5rem'}}>I have been much of a procrastinator with getting to speed on my writing. Hopefully, you will see this space updated soonest with my learnings and experiences across design, data, and digital tech risks. </h6>
                </div>
          </div>
  
          <div className='container__projects'>
              <div className='thoughtsgrid'>
              {Posts}
              </div>
              
              </div>
  
          
        </Layout>
  )
  }
  
export default Thoughts



export const query = graphql`
{
    allMarkdownRemark(
      filter: {frontmatter: {category: {eq: "thoughts"}}}
      sort: {order: DESC, fields: frontmatter___date}
    ) {
      edges {
        node {
          id
          frontmatter {
            link
            title
            slug  
            tags
            category
            date(formatString: "MMMM DD YYYY")
            description
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  transformOptions: {grayscale: false}
                )
              }
            }
          }
        }
      }
    }
  }
`
