import React from 'react'
import {Link} from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Image from '../images/Glyph/Vector1.png'


const Post = ({post}) => (
        
<div className='card__thoughts'>

        <div >
        <Link to={post.frontmatter.link} target='_blank'><GatsbyImage className="card__img" image= {post.frontmatter.featuredImage.childImageSharp.gatsbyImageData} style={{maxHeight:'calc(35vh - 4rem)'}}/></Link>
        </div>
        
        <div className='card__body'>
                <div className='card__tags'>
                        <div>
                        <p>{post.frontmatter.date} </p>
                        </div>
                        <div>
                        <p> {post.frontmatter.tags}</p>
                        </div>
                </div>

                <Link to={post.frontmatter.link} target='_blank'><h6>{post.frontmatter.title}</h6></Link>   
                <Link to={post.frontmatter.link} target='_blank'><h5>{post.frontmatter.description}</h5></Link>
                <Link to={post.frontmatter.link} target='_blank'> <p style={{color:'#292929', fontWeight:'400'}}>READ MORE <span style={{paddingLeft:'3px'}}> <img src={Image} alt="post related"/></span></p></Link>
        </div>
</div>        
)

export default Post